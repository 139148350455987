import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@/store/index';
import { historyData } from '@/constants/historyData'

export default class PostListViewModel {
  constructor() {
    this.isMobile = false;
    this.PostDataList = [];
    this.searchParams = {
      nextId:-1,
      limit:9,
    };
    this.paginationData = {
      eof:true
    };
  }
  init(){
    if(historyData.PostList.dataList.length > 0){
      this.getHistoryData();
    }else{
      this.getPostList();
    }
  }
  getPostList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.POST_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.PostDataList = this.PostDataList.concat(resultData.data);
      this.paginationData.eof = resultData.eof;
      this.searchParams.nextId = resultData.next_id;
      this.setHistoryData()
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getHistoryData(){
    this.PostDataList = historyData.PostList.dataList;
    this.searchParams = historyData.PostList.searchParams;
    this.paginationData = historyData.PostList.paginationData;
  }
  setHistoryData(){
    historyData.PostList.dataList = this.PostDataList;
    historyData.PostList.searchParams = this.searchParams;
    historyData.PostList.paginationData = this.paginationData;
  }
}